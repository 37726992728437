import React from 'react';
import { Check } from '@material-ui/icons'
const AlertIcon = <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"></path></svg>

function getIcon(type: string){
    switch(type){
        case "success":
            return <Check />
        default:
            return AlertIcon
    }
}

const Alert = (props: any) => {
    const { children, type, ...rest } = props;
    return (
        <div className={`alert-container ${type || ''}`} {...rest}>
            <div className="alert-icon">
                {getIcon(type)}
            </div>
            <div className="alert-message">
                {props.children}
            </div>
        </div>
    )
};

export default Alert;
