import React, { Component } from 'react';

interface IProps {
}

interface IState {
}

const initialState: IState = {};

export default class Preloader extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = initialState;
	}

	render() {
		return(
			<div className="preloader-wrapper big active">
				<div className="spinner-layer spinner-blue-only">
					<div className="circle-clipper left">
						<div className="circle"></div>
					</div>
					<div className="gap-patch">
						<div className="circle"></div>
					</div>
					<div className="circle-clipper right">
						<div className="circle"></div>
					</div>
				</div>
			</div>
		);
	}
}
