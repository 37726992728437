import React from 'react';
import './seo.scss';
import { Link } from 'react-router-dom';
import { Footer } from './Footer';
import ArticleLHM1 from './ArticleContents/ArticleLHMCyberpunk';
import ArticleLHMDiablo from './ArticleContents/ArticleLHMDiablo';
import ArticleLHMValhalla from './ArticleContents/ArticleLHMValhalla';
import ArticleLHMWDL from './ArticleContents/ArticleLHMWDL';

const ArticleEntry = (props: { title: string; subtitle?: string; date: string; slug: string }) => {
	return (
		<Link className="article-entry" to={`/articles/${props.slug}`}>
			<div className="left">
				<h2>{props.title}</h2>
				{props.subtitle && <h3>{props.subtitle}</h3>}
			</div>
			<h3 className="date">{props.date}</h3>
		</Link>
	);
};

const ACTIVE_THEME = process.env.REACT_APP_VARIANT || 'diablo4';
export const AVAILABLE_ARTICLES = [
	{
		title: 'LHM.gg: Revolutionizing Esports Broadcasting and Production',
		subtitle:
			'In the dynamic world of esports, delivering high-quality broadcasts and seamless production is paramount.',
		date: '2025-01-13',
		slug: 'lhm-gg-revolutionizing-esports-broadcasting-and-production',
		variant: 'cyberpunk',
		content: ArticleLHM1
	},
	{
		title: 'LHM.gg: Redefining Esports Broadcasting and Production',
		subtitle:
			'In the dynamic world of esports, delivering high-quality broadcasts and seamless production is paramount.',
		date: '2025-01-13',
		slug: 'lhm-gg-redefining-esports-broadcasting-and-production',
		variant: 'diablo4',
		content: ArticleLHMDiablo
	},
	{
		title: 'Transforming Esports with LHM.gg: A Game-Changer for Broadcasting and Production',
		subtitle:
			'The esports industry is booming, and with it comes the demand for professional, seamless broadcasting and production tools.',
		date: '2025-01-13',
		slug: 'lhm-gg-transforming-esports-broadcasting-and-production',
		variant: 'valhalla',
		content: ArticleLHMValhalla
	},
	{
		title: 'Elevate Your Esports Production with LHM.gg',
		subtitle:
			'The esports world is evolving rapidly, and so are the tools that support it. LHM.gg is a state-of-the-art platform designed to provide broadcasters, organizers, and players with everything they need for seamless esports production.',
		date: '2025-01-13',
		slug: 'elevate-your-esports-production-with-lhm-gg',
		variant: 'wdlegion',
		content: ArticleLHMWDL
	}
];

export const Articles = () => {
	return (
		<>
			<main id="articles" className={`theme-${ACTIVE_THEME}`}>
				<h1>Articles</h1>
				<div className="articles-container">
					{AVAILABLE_ARTICLES.filter(a => !a.variant || a.variant === process.env.REACT_APP_VARIANT).map(
						(article, index) => (
							<ArticleEntry key={index} {...article} />
						)
					)}
				</div>
				<div style={{ flex: 1 }} />
			</main>
			<Footer theme={ACTIVE_THEME} />
		</>
	);
};
