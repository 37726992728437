import React from "react";
import { IUser } from "./definitions";

export enum UserType {
  Unloaded,
  Guest,
  User,
  Admin,
}

export interface IUserContext {
  user?: IUser;
  type: UserType;
  logout: Function;
  login: Function;
  loadUser: Function;
  updateContext: Function;
  showHeader: boolean;
  noDrawer: boolean;
  setHeader: Function;
  setDrawer: Function;
  setTOSAccepted: Function;
}

export const defaultContext: IUserContext = {
  type: UserType.Unloaded,
  logout: function () {
    /**/
  },
  login: function () {
    /**/
  },
  loadUser: function () {
    /**/
  },
  updateContext: function () {
    /**/
  },
  showHeader: true,
  noDrawer: false,
  setHeader: function () {},
  setDrawer: function () {},
  setTOSAccepted: function () {},
};

export const UserContext = React.createContext(defaultContext);
