const convertFileToBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result || undefined);
    reader.onerror = reject;
});

const addUploadFeature = (requestHandler: any) => (type: any, resource: any, params: any) => {
	//console.log('TYPE: ' + type);
    if (type === 'UPDATE' || type === 'CREATE') {
		//console.log('PARAMSDATA: ', params.data);
        if (params.data.icon && params.data.icon.rawFile) {
			return convertFileToBase64(params.data.icon).then((icon: any) => requestHandler(type, resource, {
				...params,
				data: {
					...params.data,
					icon: icon.replace(/^data:([a-z]+)\/([a-z0-9]+);base64,/, '')
				}
			}));
        }
    }
    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);
};

export default addUploadFeature;
