import React from 'react';
import { Footer } from './Footer';
import { OpenInNew } from '@material-ui/icons';

interface ArticleProps extends React.HTMLProps<HTMLElement> {}
const ACTIVE_THEME = process.env.REACT_APP_VARIANT || 'diablo4';

export const ExLink = (props: { href: string; children: React.ReactNode; noIcon?: boolean }) => {
	return (
		<a href={props.href} target="_blank" rel="noopener noreferrer" className="external-link">
			{props.children}
			{!props.noIcon && <OpenInNew />}
		</a>
	);
};

export const Article = (props: ArticleProps) => {
	return (
		<>
			<article className={`article-content-container theme-${ACTIVE_THEME}`}>{props.children}</article>
			<Footer theme={ACTIVE_THEME} />
		</>
	);
};
