import React from 'react';
import { Tooltip } from "@material-ui/core";
import api from '../../api/api';

export default class VoteButton extends React.Component<{ status: number, markerId: number, callback: any }> {
    state = {
        status: 0,
        open: false,
        description: ''
    };

    upvote = () => {
        if (this.props.status === 1) {
            return this.remove();
        }
        api.votes.up(this.props.markerId, () => {
            this.props.callback();
        })
    }
    downvote = () => {
        if (this.props.status === -1) {
            return this.remove();
        }
        api.votes.down(this.props.markerId, () => {
            this.props.callback();
        })
    }
    remove = () => {
        api.votes.remove(this.props.markerId, () => {
            this.props.callback();
        })
    }
    render() {
        return (
            <div className="vote-container">
                <Tooltip title={'Upvote this place'} aria-label={'Upvote this place'}>
                    <div onClick={this.upvote} className={`clickable ${this.props.status === 1 ? 'active' : ''} vote-up`} ></div>
                </Tooltip>
                <Tooltip title={'Downvote this place'} aria-label={'Downvote this place'}>
                    <div onClick={this.downvote} className={`clickable ${this.props.status === -1 ? 'active' : ''} vote-down`} ></div>
                </Tooltip>
            </div>
        );
    }
}