import React from 'react';

const FOOTER_TEXTS: { [key: string]: string } = {
	diablo4: `This website is not endorsed by, directly affiliated with, maintained, authorized, or sponsored by Blizzard Entertainment, Inc.. Diablo is a trademark or registered trademark of Blizzard Entertainment, Inc., in the U.S. and/or other countries. All product and company names are the registered trademarks of their original owners. The use of any trade name or trademark is for identification and reference purposes only and does not imply any association with the trademark holder of their product brand.`,
	cyberpunk: `This website is not endorsed by, directly affiliated with, maintained, authorized, or sponsored by CD Projekt S.A.. Cyberpunk 2077 is a trademark or registered trademark of CD Projekt S.A., in the U.S. and/or other countries. All product and company names are the registered trademarks of their original owners. The use of any trade name or trademark is for identification and reference purposes only and does not imply any association with the trademark holder of their product brand.`,
	valhalla: `This website is not endorsed by, directly affiliated with, maintained, authorized, or sponsored by Ubisoft Entertainment. Assassin's Creed Valhalla is a trademark or registered trademark of Ubisoft Entertainment, in the U.S. and/or other countries. All product and company names are the registered trademarks of their original owners. The use of any trade name or trademark is for identification and reference purposes only and does not imply any association with the trademark holder of their product brand.`,
	wdlegion: `This website is not endorsed by, directly affiliated with, maintained, authorized, or sponsored by Ubisoft Entertainment. Watch Dogs: Legion is a trademark or registered trademark of Ubisoft Entertainment, in the U.S. and/or other countries. All product and company names are the registered trademarks of their original owners. The use of any trade name or trademark is for identification and reference purposes only and does not imply any association with the trademark holder of their product brand.`
};

const VARIANT = process.env.REACT_APP_VARIANT || 'diablo4';

export const Footer = (props: { theme: string }) => {
	return (
		<footer className={`articles-footer theme-${props.theme}`}>
			<p>{FOOTER_TEXTS[VARIANT] || ''}</p>
			<ul>
				<li>
					<a href="/TermsandCondition.pdf" target="_blank">
						Terms and Conditions
					</a>
				</li>
				<li>
					<a href="/PrivacyPolicy.pdf" target="_blank">
						Privacy Policy
					</a>
				</li>
			</ul>
		</footer>
	);
};
