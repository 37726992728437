import React, { Fragment } from 'react';
import { BulkDeleteButton, List, Datagrid, TextField, BooleanField, Pagination } from 'react-admin';

const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50]} {...props}/>

const BulkActionButtons = (props : any) => (<Fragment>
	<BulkDeleteButton {...props}/>
</Fragment>);

const SuggestionList = (props: any) => (
	<List {...props}
		bulkActionButtons={<BulkActionButtons/>}
		pagination={<PostPagination/>}
	>
		<Datagrid rowClick='edit'>
			<TextField source="id" sortable={true}/>
            <TextField source="name" sortable={true} label="Suggested name"/>
            <TextField source="Marker.name" sortable={true} label="Original name"/>
            <TextField source="description" sortable={false} label="Suggested description"/>
            <TextField source="Marker.description" sortable={false} label="Original description"/>
            <TextField source="Category.name" sortable={false} label="Suggested Category"/>
            <TextField source="Marker.Category.name" sortable={false} label="Original Category"/>
			
			<BooleanField source='open' />
		</Datagrid>
	</List>
);

export default SuggestionList;
