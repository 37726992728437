import React, { Fragment } from 'react';
import { BulkDeleteButton, List, Datagrid, TextField, BooleanField, Pagination } from 'react-admin';

const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50]} {...props}/>

const BulkActionButtons = (props : any) => (<Fragment>
	<BulkDeleteButton {...props}/>
</Fragment>);

const UserList = (props: any) => (
	<List {...props}
		bulkActionButtons={<BulkActionButtons/>}
		pagination={<PostPagination/>}
	>
		<Datagrid rowClick='edit'>
			<TextField source="id" sortable={true}/>
            <TextField source="username" sortable={true}/>
			<BooleanField source='admin' sortable={true} />
			<BooleanField source='banned' />
		</Datagrid>
	</List>
);

export default UserList;
