import React from 'react';
import { Admin, Resource, Layout, AppBar } from 'react-admin';
import modsDBProvider from './ModsDBAPIProvider';
import addUploadFeature from './Base64Uploader';
import { IconButton } from '@material-ui/core';
import config from '../../../api/config';

import { Category, LocationOn, AccountCircle, Report, Edit, Home, Message } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import CategoryList from './Category/CategoryList';
import UserList from './User/UserList';
import ReportList from './Reports/ReportList';
import MessageList from './Message/MessageList';

import MarkerList from './Marker/MarkerList';
import SuggestionList from './Suggestions/SuggestionList';

import { CategoryCreate, CategoryEdit } from './Category/Category';
import { MarkerCreate, MarkerEdit } from './Marker/Marker';
import { UserCreate, UserEdit } from './User/User';
import { SuggestionEdit } from './Suggestions/Suggestion';
import { ReportEdit } from './Reports/Report';
import { MessageEdit } from './Message/Message';
import JssProvider from 'react-jss/lib/JssProvider';
import { generateClassName } from './../../classGenerator';
const MyUserMenu = (props:any) => (
		<IconButton
			key="Home"
			aria-label="Home"
			color="inherit"
			component={props => <Link {...props} to={'/'} />}
		>
			<Home />
		</IconButton>
);

const MyAppBar = (props:any) => <AppBar {...props} userMenu={<MyUserMenu />} />;

const MyLayout = (props:any) => <Layout {...props} appBar={MyAppBar} />;
//const dataProvider = restProvider(config.apiAddress);
const dataProvider = addUploadFeature(modsDBProvider(config.apiAddress));

/*const Panel = () => <JssProvider generateClassName={generateClassName}><Admin dataProvider={dataProvider}>
	<Resource name='categories' icon={CategoryIcon} create={CategoryCreate} edit={CategoryEdit} list={CategoryList}/>
	<Resource name='markers' icon={CategoryIcon} create={MarkerCreate} edit={MarkerEdit} list={MarkerList}/>
	<Resource name='users' icon={CategoryIcon} create={UserCreate} edit={UserEdit} list={UserList}/>
	<Resource name='reports' icon={CategoryIcon} edit={ReportEdit} list={ReportList}/>
</Admin></JssProvider>*/
class Panel extends React.Component<any, any> {
	render() {
		return <JssProvider generateClassName={generateClassName}>
			<Admin dataProvider={dataProvider} appLayout={MyLayout}>
				<Resource name='categories' icon={Category} create={CategoryCreate} edit={CategoryEdit} list={CategoryList} />
				<Resource name='markers' icon={LocationOn} create={MarkerCreate} edit={MarkerEdit} list={MarkerList} />
				<Resource name='users' icon={AccountCircle} create={UserCreate} edit={UserEdit} list={UserList} />
				<Resource name='reports' icon={Report} edit={ReportEdit} list={ReportList} />
				<Resource name='suggestions' icon={Edit} edit={SuggestionEdit} list={SuggestionList} />
				<Resource name='messages' list={MessageList} edit={MessageEdit} icon={Message} />
			</Admin>
		</JssProvider>
  }
  
}
export default Panel;