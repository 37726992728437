import config from './config';
const apiUrl = config.apiAddress;

export enum RequestMethod {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
    PUT = 'PUT'
}

const { GET, POST, PATCH, DELETE, PUT } = RequestMethod;

interface IApiCallSettings {
    endPoint: string,
    cb: any,
    method?: RequestMethod,
    errCb?: any,
    error?: any,
    expectJson?: boolean,
    options?: any,
    body?: any
}

export function apiCall(settings:IApiCallSettings, withHeaders?: boolean){
    const options = { ...settings.options };
    options.credentials="include";
    if(settings.method === undefined) options.method = GET;
    else options.method = settings.method;
    if(options.method === POST || options.method === PATCH) options.headers = { 'Accept': 'application/json', 'Content-Type': 'application/json' };
    if(settings.body) options.body = JSON.stringify(settings.body); 

	const errCb = settings.errCb || ((err: any) => { })//console.log('Error on API call: ', err));
    let status = 0;
    const newstr = apiUrl + (apiUrl[apiUrl.length-1] === "/" && settings.endPoint[0] === "/" ?  settings.endPoint.substr(1) : settings.endPoint);

    fetch(newstr, options)
		.then(res => {
			return res.json().then(data => {
				status = res.status;
				if(withHeaders) {
					return { headers: res.headers, data: data, status };
				}
				else return data;
			}).catch(_e => {
				status = res.status;
				return {headers: res.headers, data: res.body, status};
			});
		})
        .then(res => {
            if(400 <=status && !res.errors) res.errors = []; 
            if(res.errors) errCb(res);
            else settings.cb(res);
        })
        .catch(errCb);
}

export default {
    user: {
        getMyself: (cb: any, errCb?: any) => apiCall({ endPoint: '/auth', cb, errCb}),
        get: (cb: any, errCb?: any) => apiCall({endPoint: '/users',cb, errCb}),
		getSingle: (id: number, cb: any, errCb?: any) => apiCall({ endPoint: '/users/'+id, cb, errCb }),
        register: (user: {username:string, password:string}, cb: any, errCb?: any) => apiCall({ endPoint: '/auth/register', method: POST, body:user, cb, errCb}),
        login: (user: {username:string, password: string}, cb: any, errCb?: any) => apiCall({ endPoint: 'auth', method: POST, cb, body:user, errCb}),
        logout: (cb: any, errCb?: any) => apiCall({ endPoint: '/auth', cb, errCb, method:DELETE }),
        update: (u_id:number, body:any, cb:any, errCb?:any) => apiCall({endPoint:'/users/'+u_id, body, method:PATCH, cb, errCb}),
        delete: (userId: number, cb: any, errCb?: any) => apiCall({endPoint:`/users/${userId}`, method: DELETE, cb, errCb}),
        reset: (email: string, token:string, cb: any, errCb?: any) => apiCall({endPoint:`/reset`, method:POST, body:{email, token}, cb, errCb}),
        savePassword: (token: string, password: string, cb: any, errCb?: any) => apiCall({endPoint:`/reset`, method:PATCH, body:{password, token}, cb, errCb}),
        //getById: () => apiCall({endPoint:'/test', method:GET, callback:(res:any)=>console.log(res)})
    },
    markers: {
        get: (query: {}, cb: any, errCb?:any) => {
            let endPoint = '/markers';
            //query: {page?:number,category?:number,tag?:number, query?:string, creator?:number, sort?:"name"|"newest"|"views"|"downloads",filter?:"upvoted"|"downvoted"|"watched"}, cb: any, errCb?:any
            /*if(query.category) endPoint += "&category="+query.category;
			//if(query.categorySlug) endPoint += "&categoryslug="+query.categorySlug;
            if(query.tag) endPoint += "&tag="+query.tag;
            if(query.query) endPoint += "&query="+query.query;
            if(query.creator) endPoint += "&creator="+query.creator;
            if(query.sort) endPoint += "&sort="+query.sort;
            if(query.filter) endPoint += "&filter="+query.filter;
            if(query.page) endPoint += "&page="+query.page;*/

            return apiCall({endPoint, cb, errCb});
        },
        update: (markerId: number, body: any, cb: any, errCb?: any) => apiCall({endPoint:`/markers/${markerId}`, body, method: PATCH, cb, errCb}),
        add: (marker: {name:string, description: string, position: any}, cb: any, errCb?: any) => apiCall({endPoint:'/markers', method:POST, body: marker, cb, errCb}),
        setFound: (markerId: number, cb: any, errCb?: any) => apiCall({endPoint:`/found/${markerId}`, method: POST, cb, errCb}),
        removeFound: (markerId: number, cb: any, errCb?: any) => apiCall({endPoint:`/found/${markerId}`, method: DELETE, cb, errCb}),
        report: (marker: number, description: string, cb: any, errCb?:any) => apiCall({endPoint:`/reports`, method:POST, body: {description, marker}, cb, errCb})
    },
    categories: {
		get: (cb: Function, errCb?: Function) => apiCall({endPoint:'/categories', cb, errCb}),
		getSingle: (id: number, cb: Function, errCb?: Function) => apiCall({endPoint:'/categories/'+id, cb, errCb}),
		getFromSlug: (slug: string, cb: Function, errCb?: Function) => apiCall({endPoint:'/categories/slug/'+slug, cb, errCb})
    },
    suggestions: {
        get: (cb: Function, errCb?: Function) => apiCall({endPoint:'/suggestions', cb, errCb}),
        update: (suggId: number, body: any, cb: any, errCb?: any) => apiCall({endPoint:`/suggestions/${suggId}`, body, method: PATCH, cb, errCb}),
        add: (marker: {name:string, description: string, marker: number}, cb: any, errCb?: any) => apiCall({endPoint:'/suggestions', method:POST, body: marker, cb, errCb}),
        delete: (markerId: number, cb: any, errCb?: any) => apiCall({endPoint:`/suggestions/${markerId}`, method: DELETE, cb, errCb}),
    },
    votes: {
        get: (cb: Function, errCb?: Function) => apiCall({endPoint:'/votes', cb, errCb}),
        up: (markerId: number, cb: Function, errCb?: Function) => apiCall({endPoint: `/upvote/${markerId}`, method: POST, cb, errCb}),
        down: (markerId: number, cb: Function, errCb?: Function) => apiCall({endPoint: `/downvote/${markerId}`, method: POST, cb, errCb}),
        remove: (markerId: number, cb: Function, errCb?: Function) => apiCall({endPoint: `/vote/${markerId}`, method: DELETE, cb, errCb})
    },
    message: {
        add: (body: {topic: string, email: string, message: string}, cb: any, errCb?: any) => apiCall({endPoint: '/messages', method: POST, body, cb, errCb})
    }
}
